import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  Input,
  message,
  DatePicker,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { lifeOccasionOptionArray, toProperCase } from '../../utils/generic';
import {
  getMonthNameFromStringDate,
  getMonthIndex,
  getDateFromMonth,
  dateFormat,
  monthNames,
} from '../../utils/dateUtils';

const filterGlobalSearch = (Data, searchText, filterScope) => {
  if (!searchText) {
    return Data;
  }

  let filteredDataTemp = [];
  if (filterScope == 'all') {
    filteredDataTemp = Data.filter((value) => {
      return (
        value.productCategoryName
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase()) ||
        value?.discription
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase()) ||
        value?.genericType
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase()) ||
        value?.lifeOccasion?.toLowerCase()?.includes(searchText?.toLowerCase())
      );
    });
  } else if (filterScope == 'productCategoryName') {
    filteredDataTemp = Data.filter((value) => {
      return value?.productCategoryName
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    });
  } else if (filterScope == 'discription') {
    filteredDataTemp = Data.filter((value) => {
      return value?.discription
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    });
  } else if (filterScope == 'genericType') {
    filteredDataTemp = Data.filter((value) => {
      return value?.genericType
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    });
  } else if (filterScope == 'lifeOccasion') {
    filteredDataTemp = Data.filter((value) => {
      return value?.lifeOccasion
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    });
  }

  // Disabled: no need to map supplier to fabric
  //  else if (filterScope == 'suppliers') {
  //   filteredDataTemp = Data.filter((value) => {
  //     return value.supplierDetails.supplierName
  //       .toLowerCase()
  //       .includes(searchText.toLowerCase());
  //   });
  // }

  console.log(filteredDataTemp, 'filtered data', filterScope, searchText);
  if (filteredDataTemp.length > 0) {
    return filteredDataTemp;
  } else {
    filteredDataTemp.push({
      key: 0,
      id: 0,
      productCategoryName: 'Not Found',
      discription: 'Not Found',
      genericType: 'Not Found',
      lifeOccasion: 'Not Found',
      designProductCode: 'Not Found',
      // Disabled: no need to map supplier to fabric
      // supplierDetails: 'Not Found',
    });
    return filteredDataTemp;
  }
};

const ProductOptionType = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  // Edit Data for editing existing record
  const [editData, setEditData] = useState(null);
  const [launchMastersList, setLaunchMastersList] = useState([]);

  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [Data, setData] = useState([]);
  const [dataform] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);

  const [sizeList, setSizeList] = useState([]);
  const [productSubCategoryList, setProductSubCategoryList] = useState([]);
  //options for form

  const [filterCloumnData, setFilterCloumnData] = useState([]);
  const [filterInfo, setFilterInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState('');

  //filter global
  const [searchText, setSearchText] = useState('');
  const [filterScope, setFilterScope] = useState('all');

  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);
  const filterScopeOptionsArray = [
    'all',
    'productCategoryName',
    'discription',
    'genericType',
    'lifeOccasion',
    // Disabled: no need to map supplier to fabric
    // 'suppliers',
  ];

  let data1 = [];

  // Get main Data
  const fetchData = async (token) => {
    setIsLoading(true);
    try {
      // fetch data from database
      const response = await fetch(
        `${API}/product-option-types?populate[sizes][populate]=true&populate[product_sub_category][populate]=true&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        // return;
        data1 = responseData.data.map((entry) => {
          console.log(
            responseData,
            // entry.attributes?.launch_master?.data,
            'check'
          );

          let sizeObjectArray = [];
          if (entry.attributes?.sizes?.data?.length > 0) {
            console.log(entry.attributes?.sizes?.data);
            sizeObjectArray = entry.attributes?.sizes?.data.map((sizeObj) => {
              console.log(sizeObj);
              return {
                sizeRefId: sizeObj?.id,
                sizeName: sizeObj.attributes?.size_name,
              };
            });
          }

          let productSubCatObject = {};
          if (entry.attributes?.product_sub_category?.data) {
            console.log(
              'asdasdasd -- sdsa',
              entry.attributes?.product_sub_category?.data
            );
            const productSubCatObj =
              entry.attributes?.product_sub_category?.data;
            productSubCatObject = {
              subProductCategoryRefId: productSubCatObj?.id,
              subProductCategoryName:
                productSubCatObj.attributes?.sub_category_name,
            };
          }

          return {
            key: entry.id,
            id: entry.id,
            productCategoryName: entry?.attributes?.productCategoryName,
            discription: entry?.attributes?.discription,
            genericType: entry?.attributes?.genericType,
            lifeOccasion: entry?.attributes?.lifeOccasion,
            designProductCode: entry?.attributes?.designProductCode,
            sizeDetails: sizeObjectArray,
            productSubCategoryDetails: productSubCatObject,
          };
        });

        console.log('original Data:', responseData);
        console.log('current Data after modification:', data1);

        setData(filterGlobalSearch(data1, searchText, filterScope));
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSizeList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/sizes?sort[0]=createdAt:desc`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          sizeName: entry.attributes.size_name,
        };
      });

      setSizeList(optionsData2);
      console.log('original color option Data:', optionsData);
      console.log('modified color Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProuductSubCategoryList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/product-sub-categories?sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          subCategoryName: entry.attributes.sub_category_name,
        };
      });

      setProductSubCategoryList(optionsData2);
      console.log('original Sub Product Cat Data:', optionsData);
      console.log('modified Sub Product Cat Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  //   const fetchLaunchList = async (token) => {
  //     let optionsData2 = [];
  //     setIsLoading(true);
  //     try {
  //       const response = await fetch(
  //         `${API}/launch-masters?&populate[season][fields][0]=season_id&createdAt:desc`,
  //         {
  //           headers: { Authorization: `${BEARER} ${token}` },
  //         }
  //       );
  //       const optionsData = await response.json();

  //       optionsData2 = optionsData.data.map((entry) => {
  //         return {
  //           id: entry.id,
  //           launchName: entry.attributes.launchName,
  //           launchDate: entry.attributes.launchDate,
  //           launchDrop: entry.attributes.launchDrop,
  //           //   launchCat: entry.attributes.launchCat,

  //           seasonName: entry.attributes.season?.data?.attributes.season_id,
  //           // launch_masters: entry.attributes.launch_masters,
  //         };
  //       });

  //       setLaunchMastersList(optionsData2);
  //       console.log('original option Data:', optionsData);
  //       console.log('modified Data:', optionsData2);
  //     } catch (error) {
  //       console.error(error);
  //       message.error('Error while fetching Data!');
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  // Disabled: no need to map supplier to fabric
  // selecting the dropdowns data
  // const fetchSupplier = async (token) => {
  //   let supplierData2 = [];
  //   setIsLoading(true);
  //   try {
  //     const response = await fetch(`${API}/suppler-masters?`, {
  //       headers: { Authorization: `${BEARER} ${token}` },
  //     });
  //     const responseData = await response.json();
  //     console.log(responseData);
  //     if (responseData.error) {
  //       message.error('Something went wrong, please try again');
  //     } else {
  //       supplierData2 = responseData.data.map((entry) => {
  //         return {
  //           id: entry.id,
  //           supplierName: entry.attributes.supplierName,

  //           // launch_masters: entry.attributes.launch_masters,
  //         };
  //       });

  //       setSupplierList(supplierData2);
  //       console.log('original supplier Data:', responseData);
  //       console.log('modified supplier Data:', supplierData2);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     message.error('Error while fetching Data!');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }; // end function fetchSeason

  useEffect(() => {
    if (authToken) {
      fetchData(authToken);
      fetchSizeList(authToken);
      fetchProuductSubCategoryList(authToken);
      //   fetchLaunchList(authToken);
      // Disabled: no need to map supplier to fabric
    }
  }, []);

  // for opening add or edit form with data or change in the reference of data form when form is closed or page is reloaded
  useEffect(() => {
    //dataform data
    console.log(editData);
    dataform.setFieldsValue(editData);
  }, [editData, dataform]);

  // show the modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // hide and reset add/edit form inside modal
  const handleOk = () => {
    dataform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  // hide and reset add/edit form inside modal
  const handleCancel = () => {
    dataform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  //add record to database table by api call
  const addNewRecord = async (data) => {
    setSaving(true);

    //add or removing extra data from values from formData object
    const values = {
      ...data,
      //   indent_file_date: data['indent_file_date'].format('YYYY-MM-DD'),
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);
    // return;
    //editId will be present only in case of edit mode
    console.log('data edit:', data.data.editId);

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);
      // update the record
      try {
        const response = await fetch(
          `${API}/product-option-types/${data.data.editId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Update Record, ' + responseData.error);
        } else {
          message.success('Data saved successfully!');

          fetchData(authToken);
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        dataform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data.editId); //should be falsy

      try {
        const response = await fetch(`${API}/product-option-types/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Record, ' + responseData.error);
        } else {
          message.success('Data saved successfully!');
          //
          fetchData(authToken);
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        dataform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    }
  };
  //end function addNewRecord

  const columns = [
    {
      key: 'productCategoryName',
      title: 'Product Category Name',
      dataIndex: 'productCategoryName',
    },

    {
      key: 'discription',
      title: 'Discription',
      dataIndex: 'discription',
    },
    {
      key: 'product_sub_category',
      title: 'Product Sub Category',
      render: (_, record) => {
        return record?.productSubCategoryDetails?.subProductCategoryName || '';
      },
    },
    {
      key: 'designProductCode',
      title: 'Design Product Code',
      dataIndex: 'designProductCode',
    },
    {
      key: 'lifeOccasion',
      title: 'Life Occasion',
      dataIndex: 'lifeOccasion',
    },

    //Disabled: no need to map supplier to fabric
    // {
    //   key: 'suppliers',
    //   title: 'supplier Name',
    //   dataIndex: 'suppliers',
    //   render: (_, record) =>
    //     record.supplierDetails.supplierName
    //       ? record.supplierDetails.supplierName
    //       : 'NA',
    // },
    {
      key: 'action',
      title: 'Actions',

      render: (_, record) => {
        return (
          <div>
            <div className="ActionColumn">
              <EditOutlined
                style={{ color: 'black' }}
                onClick={() => Edit(record)}
              />
              <Button
                icon={
                  <DeleteOutlined style={{ color: 'red', marginRight: 8 }} />
                }
                onClick={() => Delete(record)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const deleteRecord = async (recordID) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/product-option-types/${recordID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        fetchData(getToken());
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const Delete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this',
      onOk: () => {
        deleteRecord(record.id);
      },
    });
  };

  const Edit = (record) => {
    console.log(record, '419');

    // set data for add or edit form
    setEditData({
      productCategoryName: record.productCategoryName,
      discription: record.discription,
      genericType: record.genericType,
      lifeOccasion: record.lifeOccasion,
      designProductCode: record.designProductCode,
      sizes: record.sizeDetails?.map((size) => size.sizeRefId),
      product_sub_category:
        record.productSubCategoryDetails?.subProductCategoryRefId,

      // Disabled: no need to map supplier to fabric
      // suppliers: record.supplierDetails.supplierRefId,
      editId: record.id, // its important for edit functionality
    });
    showModal(); // after setting the data for the edit form show the modal
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log('in handle table change', pagination, filters, sorter);
    const { order, columnKey } = sorter;
    setFilterInfo(filters);
    setSortedInfo({ columnKey, order });
  };

  //reset table
  const resetTable = () => {
    setSortedInfo({});
    setFilterInfo({});
    setSearchText('');
    setFilterScope('all');
    setAlreadySelectedRows([]);
    fetchData(authToken);
  };

  const handleChangeSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText == '') {
      fetchData(authToken);
    } else {
      fetchData(authToken);
    }
  }, [searchText, filterScope]);

  const handleFilterScopeChange = (value) => {
    console.log('setFilterScope', value);
    setFilterScope(value);
  };

  const getFilterOptionArray = (options) => {
    return options.map((option) => {
      return { value: option, label: option };
    });
  };

  const filterOptionArray = [...getFilterOptionArray(filterScopeOptionsArray)];

  //   const launchListOptions = launchMastersList.map((launch) => {
  //     return {
  //       label: launch.launchName,
  //       value: launch.id,
  //     };
  //   });

  const getFormattedData = () => {
    const downloadData = Data;
    // console.log(downloadData, 'downloading data...');
    // return [];
    let downloadDataFormatted = downloadData.map((row) => {
      console.log(row, 'downloading data row...');
      return {
        'Product Category Name': row.productCategoryName,
        Discription: row.discription,
        'Generic Type': row.genericType,
        'Life Occasion': row.lifeOccasion,
        'Design Product Code': row.designProductCode,
      };
    });

    console.log(downloadDataFormatted, 'downloading data 2...');
    return downloadDataFormatted;
  };

  const lifeOccasionOptionList = lifeOccasionOptionArray.map((value) => {
    return {
      label: toProperCase(value),
      value: value,
    };
  });

  const sizeOptionList = sizeList?.map((size) => {
    return {
      label: toProperCase(size?.sizeName),
      value: size?.id,
    };
  });

  const productSubCategoryOptionList = productSubCategoryList?.map(
    (subCategory) => {
      return {
        label: toProperCase(subCategory?.subCategoryName),
        value: subCategory?.id,
      };
    }
  );

  return (
    <div>
      <h2>Product Option Type Master</h2>
      {/* Modal */}
      <Button onClick={showModal}>Add Product Option Type</Button>

      {/* Table Section */}
      <div className="table">
        {/* Filter search Section */}
        <div className="filterContainer">
          <div className="tableSearchSection">
            <Select
              defaultValue="all"
              className="filterScopeSelect"
              onChange={handleFilterScopeChange}
              options={filterOptionArray}
            />

            <Input
              placeholder="Enter Search Text"
              onChange={handleChangeSearch}
              type="text"
              allowClear
              value={searchText}
            ></Input>
            <Button onClick={resetTable}>Reset</Button>
            <Button style={{ backgroundColor: 'greenyellow', color: 'white' }}>
              <CSVLink
                data={getFormattedData()}
                filename={
                  'Product-option-type-list-' +
                  moment(new Date()).format('DD-MM-YYYY')
                }
              >
                Download
              </CSVLink>
            </Button>
          </div>
        </div>
        <Table
          dataSource={Data}
          columns={columns}
          onChange={handleTableChange}
          pagination={{ position: ['bottomCenter'] }}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: alreadySelectedRows,
            onChange: (keys) => {
              setAlreadySelectedRows(keys);
            },
            onSelect: (record) => {
              console.log(record, 'selected row ');
            },
            selections: [Table.SELECTION_NONE, Table.SELECTION_ALL],
          }}
        />
      </div>

      {/* Modal For Add Or Edit */}
      <Modal
        title="Add Product Option Type"
        visible={isModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Card className="addProduct_page_card">
          <Form form={dataform} layout="vertical" onFinish={addNewRecord}>
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Product Category Name"
                  name="productCategoryName"
                  rules={[
                    {
                      required: true,
                      message: 'Product Category Name is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Product Category" />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Description"
                  name="discription"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Description" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Design Product Code"
                  name="designProductCode"
                  rules={[
                    {
                      // required: true,
                      // message: 'Product Code Name is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Design Product Code" />
                </Form.Item>
              </Col>

              {/**
                // ---- Disabled as product sub category is created ---  
                <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Generic Type" name="genericType">
                  <Select placeholder="Select Generic Type">
                    <Select.Option value="top">Top</Select.Option>
                    <Select.Option value="bottom">Bottom</Select.Option>
                    <Select.Option value="accessory">Accessory</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

                 */}

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Life Occasion" name="lifeOccasion">
                  <Select
                    showSearch
                    onSearch={(value) => {
                      // console.log('searching...', value);
                    }}
                    placeholder="Select "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.trim().toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={lifeOccasionOptionList}
                  />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Product Sub Category"
                  name="product_sub_category"
                >
                  <Select
                    showSearch
                    onSearch={(value) => {
                      // console.log('searching...', value);
                    }}
                    placeholder="Select "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.trim().toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={productSubCategoryOptionList}
                  />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Sizes" name="sizes">
                  <Select
                    showSearch
                    mode="multiple"
                    onSearch={(value) => {
                      // console.log('searching...', value);
                    }}
                    placeholder="Select "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.trim().toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={sizeOptionList}
                  />
                </Form.Item>
              </Col>

              <Form.Item label="Username" hidden={true} name="editId">
                <Input type="text" />
              </Form.Item>
            </Row>
            <Button
              className="addProduct_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {saving ? (
                <>
                  <Spin size="small" /> Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};
export default ProductOptionType;
