import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import ItemListDisplay from '../../components/ItemList/ItemListDispaly';
import { getToken } from '../../helpers';
import { CSVLink } from 'react-csv';
import {
  useNavigate,
  Link,
  useSearchParams,
  useLocation,
} from 'react-router-dom';

import {
  getMonthNameFromStringDate,
  compareMonth,
  sortRecordsByMonth,
} from '../../utils/dateUtils';
import moment from 'moment';

const launchNameFilterFunciton = (value, searchText) => {
  let flag = false;
  value?.launchDetails?.forEach((launch) => {
    console.log(
      launch?.launchName,
      'value - ',
      launch?.launchName
        ?.toLowerCase()
        .includes(searchText?.trim().toLowerCase())
    );
    if (
      launch?.launchName
        ?.toLowerCase()
        .includes(searchText?.trim().toLowerCase())
    ) {
      flag = true;
    }
  });
  return flag;
};

const indentSheetFilterFunciton = (value, searchText) => {
  let flag = false;
  value?.indentSheetDetails?.forEach((indent) => {
    console.log(
      indent?.indentReffName,
      'value - ',
      indent?.indentReffName
        ?.toLowerCase()
        .includes(searchText?.trim().toLowerCase())
    );
    if (
      indent?.indentReffName
        ?.toLowerCase()
        .includes(searchText?.trim().toLowerCase())
    ) {
      flag = true;
    }
  });
  return flag;
};

const filterGlobalSearch = (Data, searchText, filterScope) => {
  if (!searchText) {
    return Data;
  }

  let filteredDataTemp = [];
  if (filterScope == 'all') {
    filteredDataTemp = Data.filter((value) => {
      console.log(value);
      return (
        value?.launchDrop
          ?.toLowerCase()
          .includes(searchText.trim().toLowerCase()) ||
        value?.month?.toLowerCase().includes(searchText.trim().toLowerCase()) ||
        value?.productOptionType?.productCatName
          ?.toLowerCase()
          .includes(searchText.trim().toLowerCase()) ||
        value?.status
          ?.toLowerCase()
          .includes(searchText.trim().toLowerCase()) ||
        launchNameFilterFunciton(value, searchText) ||
        value?.status
          ?.toLowerCase()
          .includes(searchText.trim().toLowerCase()) ||
        indentSheetFilterFunciton(value, searchText)
      );
    });
  } else if (filterScope == 'launchDrop') {
    filteredDataTemp = Data.filter((value) => {
      return value?.launchDrop
        ?.toLowerCase()
        .includes(searchText.trim().toLowerCase());
    });
  } else if (filterScope == 'launch_masters') {
    filteredDataTemp = Data.filter((value) => {
      console.log('searchValue:', searchText, value);
      return launchNameFilterFunciton(value, searchText);
    });
  } else if (filterScope == 'month') {
    filteredDataTemp = Data.filter((value) => {
      console.log('searchValue:', searchText, value);
      return value?.month
        ?.toLowerCase()
        .includes(searchText.trim().toLowerCase());
    });
  }

  console.log(filteredDataTemp, 'filtered data', filterScope, searchText);
  if (filteredDataTemp.length > 0) {
    return filteredDataTemp;
  } else {
    filteredDataTemp.push({
      key: 0,
      id: 0,
      month: 'Not Found',
      launchDrop: 'Not Found',
      category: 'Not Found',
      quantity: 'Not Found',
      assumedMRP: 'Not Found',
      status: 'Not Found',
      launchDetails: [],
      productOptionType: {
        productCatName: 'Not Found',
        productCatRefId: 0,
      },
      indentSheetDetails: [],
    });
    return filteredDataTemp;
  }
};

const ProductOptionList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page'), 10) || 1;

  const [isLoading, setIsLoading] = useState(null);

  const authToken = getToken();
  const [Data, setData] = useState([]);
  const [indentList, setIndentList] = useState([]);
  const [filterInfo, setFilterInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState('');
  const [isIndentModalOpen, setIsIndentModalOpen] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [filterScope, setFilterScope] = useState('all');

  const filterScopeOptionsArray = [
    'all',
    'launchDrop',
    'month',
    'launch_masters',
  ];

  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);

  console.log(location, '58');
  console.log(alreadySelectedRows, '59');
  // not used
  let searchPLaunchName = searchParams?.get('launchName')
    ? searchParams.get('launchName')
    : null;
  console.log(searchPLaunchName);

  let filterParam = location.state
    ? `&filters[launch_masters][id][$eq]=${location.state}`
    : '';
  console.log(filterParam);

  const toPreviousPage = () => {
    navigate(-1);
  };

  const toProductOptionDetails = (state) => {
    navigate('/productOptionDetails', { state });
  };

  let data1 = [];

  const fetchData = async (token) => {
    setIsLoading(true);
    try {
      //  ${
      //     location?.state && `&filters[otb_record][id][$eq]=${location?.state}`
      //   }
      const response = await fetch(
        `${API}/product-options?populate[0]=launch_masters&populate[1]=product_option_type&populate[2]=indent_sheets${filterParam}&sort=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const responseData = await response.json();
      console.log(responseData, 'response check');
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        // return;

        data1 = responseData.data.map((entry) => {
          let launchObj = [];
          if (entry.attributes.launch_masters?.data?.length > 0) {
            // console.log(entry.attributes.launch_masters?.data);
            launchObj = entry.attributes.launch_masters?.data.map(
              (launchMasterObject) => {
                console.log(launchMasterObject);
                return {
                  launchRefId: launchMasterObject.id,
                  launchName: launchMasterObject.attributes.launchName,
                  launchCategory: launchMasterObject.attributes.launchCat,
                  launchMonth: launchMasterObject.attributes.launchMonth,
                  launchDrop: launchMasterObject.attributes.launchDrop,
                  launchDate: launchMasterObject.attributes.launchDate,
                };
              }
            );
          }

          let indentSheetsObjArray = [];
          if (entry.attributes.indent_sheets?.data?.length > 0) {
            // console.log(entry.attributes.indent_sheets?.data);
            indentSheetsObjArray = entry.attributes.indent_sheets?.data.map(
              (indentSheetObject) => {
                console.log(indentSheetObject);
                return {
                  indentRefId: indentSheetObject.id,
                  indentReffName:
                    indentSheetObject.attributes.Indent_file_reference_id,
                  indentRefLink: indentSheetObject.attributes.Indent_file_link,
                  indentDate: indentSheetObject.attributes.indent_file_date,
                  indentType: indentSheetObject.attributes.indent_type,
                };
              }
            );
          }

          let productOptionTypeObject = {};
          if (entry.attributes.product_option_type) {
            let productOptionType = entry.attributes.product_option_type;
            productOptionTypeObject = {
              //productCat -> product option type, example: shirt, crew, pants, etc
              productCatRefId: productOptionType.data.id,
              productCatName:
                productOptionType.data.attributes.productCategoryName,
            };
          }

          //   let productOptionList = [];
          //   if (entry.attributes.product_option_qties?.data?.length > 0) {
          //
          //     productOptionList = entry.attributes.product_option_qties?.data.map(
          //       (productOptionQtyObject) => {
          //         let productOptionObject =
          //           productOptionQtyObject.attributes.product_option_type;
          //         let poqId = productOptionQtyObject.id;
          //         let quantity = productOptionQtyObject.attributes.quantity;
          //         let poObject = {
          //           productCatName:
          //             productOptionObject.data.attributes.productCategoryName,
          //           productCatId: productOptionObject.data.id,
          //         };

          //         return { poqId, quantity, poObject };
          //       }
          //     );
          //   }

          return {
            key: entry.id,
            id: entry.id,
            month: entry.attributes.month,
            launchDrop: entry.attributes.launchDrop,
            category: productOptionTypeObject?.productCatName,
            quantity: entry.attributes.qty,
            assumedMRP: entry.attributes.assumedMRP,
            launchDetails: launchObj,
            productOptionType: productOptionTypeObject,
            indentSheetDetails: indentSheetsObjArray,
            status: entry.attributes.status,
          };
        });

        console.log('original Data:', responseData);
        console.log('current Data after modification:', data1);
        // setData(await sortRecordsByMonth(data1));
        setData(filterGlobalSearch(data1, searchText, filterScope));
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchData(authToken);
    }
  }, []);

  useEffect(() => {
    console.log(searchText);
    if (searchText == '') {
      fetchData(authToken);
    } else {
      fetchData(authToken);
    }
  }, [searchText, filterScope]);

  const showIndentModal = (record) => {
    console.log('this is inside showIndentModal', record);
    setIndentList(
      record?.indentSheetDetails?.map((indent) => indent?.indentReffName)
    );

    setIsIndentModalOpen(true);
  };

  const handleIndentOk = () => {
    // modalform.resetFields();
    // setEditData(null);
    setIsIndentModalOpen(false);
  };
  const handleIndentCancel = () => {
    // modalform.resetFields();
    // setEditData(null);
    setIsIndentModalOpen(false);
  };
  // if (isLoading) {
  //   return <Spin size="large" />;
  // }

  const columns = [
    {
      key: 'month',
      title: 'Month',
      dataIndex: 'month',
    },
    {
      key: 'launchDrop',
      title: 'Launch Drop',
      dataIndex: 'launchDrop',
    },
    {
      key: 'category',
      title: 'Category',
      dataIndex: 'category',
    },
    {
      key: 'IndentSheetDetails',
      title: 'Indent Sheets',
      render: (_, record) => {
        console.log('recode check indent', record);
        return record.indentSheetDetails.map((indentInfo) => {
          return (
            <li onClick={() => showIndentModal(record)}>
              {indentInfo?.indentReffName}
            </li>
          );
        });

        // return record?.indentSheetDetails?.indentReffName;
      },
    },
    {
      key: 'quantity',
      title: 'Quantity',
      dataIndex: 'quantity',
    },
    {
      key: 'assumedMRP',
      title: 'Assumed MRP',
      dataIndex: 'assumedMRP',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (_, record) => {
        switch (record.status) {
          case 'confirmed':
            return 'confirmed';
            break;
          case 'core_prod_generated':
            return 'Core Product Generated';
            break;
          default:
            return record.status;
            break;
        }
      },
    },
    {
      key: 'action',
      title: 'Actions',

      render: (_, record) => {
        return (
          <div>
            <div className="ActionColumn">
              <EditOutlined
                style={{ color: 'black' }}
                onClick={() => Edit(record)}
              />
              <Button
                disabled={record.status === 'core_prod_generated'}
                icon={
                  <DeleteOutlined style={{ color: 'red', marginRight: 8 }} />
                }
                onClick={() => Delete(record)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const deleteRecord = async (recordID) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/product-options/${recordID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        fetchData(getToken());
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const Delete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this',
      onOk: () => {
        deleteRecord(record.id);
      },
    });
  };

  const Edit = (record) => {
    toProductOptionDetails(record);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log('in handle table change', pagination, filters, sorter);
    const { order, columnKey } = sorter;
    setFilterInfo(filters);
    setSortedInfo({ columnKey, order });
    const { current } = pagination;
    setSearchParams({ page: current });
  };

  const resetTable = () => {
    setSortedInfo({});
    setFilterInfo({});
    setSearchText('');
    setFilterScope('all');
    setAlreadySelectedRows([]);
    fetchData(authToken);
  };

  const handleChangeSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleFilterScopeChange = (value) => {
    console.log('setFilterScope', value);
    setFilterScope(value);
  }; // change in filter scope selected by user

  const getFilterOptionArray = (options) => {
    return options.map((option) => {
      return { value: option, label: option };
    });
  }; //

  const filterOptionArray = [...getFilterOptionArray(filterScopeOptionsArray)];

  const confirmAllselectedRecords = async () => {
    console.log(alreadySelectedRows, 'confirm all selected records func');
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/product-option/confirm-product-option`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({
            data: { selectedProductOptionRecords: alreadySelectedRows },
          }),
        }
      );
      const responseData = await response.json();
      console.log(responseData);

      if (responseData?.error) {
        if (responseData?.errorInfo?.status == 403) {
          message.error(
            'Unable to Process your Request, ' + responseData?.error
          );
        } else {
          message.error('Something went wrong, please try again');
        }
      } else {
        message.success('All Selected Records are confirmed Successfully');
        fetchData(getToken());
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const otbRecordStatusLookupObject = {
    active: 'Active',
    inactive: 'Inactive',
    wip: 'Wip',
    complete: 'Complete',
    dropped: 'Dropped',
    deleted: 'Deleted',
    confirmed: 'Confirmed',
    core_prod_generated: 'Product Generated',
  };
  const getDisplayableStatus = (sys_status) => {
    return otbRecordStatusLookupObject[sys_status];
  };

  const getFormattedData = () => {
    const downloadData = Data;
    console.log(downloadData, 'downloading data...');

    let downloadDataFormatted = downloadData.map((row) => {
      console.log(row, 'downloading data row...');
      return {
        Month: row.month,
        'Launch Drop': row.launchDrop,
        Quantity: row.quantity,
        'Assumed MRP': row.assumedMRP,
        'Launch Name': row.launchDetails
          .map((launch) => launch?.launchName)
          .join(','),
        'Product Option Type': row.productOptionType?.productCatName,
        'Indent Attached': row.indentSheetDetails
          .map((indentSheetObj) => indentSheetObj?.indentRefLink)
          .join(','),
        status: getDisplayableStatus(row.status),
      };
    });

    console.log(downloadDataFormatted, 'downloading data 2...');
    return downloadDataFormatted;
  };

  const generateCoreProductForAllselectedRecords = async () => {
    console.log(
      alreadySelectedRows,
      'gen core product all selected records func'
    );
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/product-option/generate-developmentProduct`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({
            data: { selectedProductOptionRecords: alreadySelectedRows },
          }),
        }
      );
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        if (responseData?.data?.incompleteSuccess.length > 0) {
          message.warn('completed with error for some Product Options');
        } else {
          message.success('completed core Product generation');
        }

        // fetchData(getToken());
        resetTable();
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Product Option Listing</h2>
        {/* Back Button*/}
        <Button type="link" onClick={toPreviousPage}>
          Back
          <RollbackOutlined />
        </Button>
      </div>

      <Button
        onClick={() => {
          toProductOptionDetails();
        }}
      >
        Add Product Option
      </Button>

      {/* Table Section */}
      <div className="table">
        {/* Filter search Section */}
        <div className="filterContainer">
          <div className="tableSearchSection">
            <Select
              disabled={true}
              defaultValue="all"
              className="filterScopeSelect"
              onChange={handleFilterScopeChange}
              options={filterOptionArray}
            />

            <Input
              placeholder="Enter Search Text"
              onChange={handleChangeSearch}
              type="text"
              allowClear
              value={searchText}
            ></Input>
            <Button
              onClick={() => {
                filterParam = '';
                resetTable();
              }}
            >
              Reset
            </Button>
            <Button style={{ backgroundColor: 'greenyellow', color: 'white' }}>
              <CSVLink
                data={getFormattedData()}
                filename={
                  'Product-Option-list-' +
                  moment(new Date()).format('DD-MM-YYYY')
                }
              >
                Download
              </CSVLink>
            </Button>
          </div>
        </div>

        {isLoading ? (
          <Spin size="large" />
        ) : (
          <Table
            dataSource={Data}
            columns={columns}
            onChange={handleTableChange}
            pagination={{
              current: currentPage,
              pageSize: 10,
              total: Data.length,
              position: ['bottomCenter'],
            }}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: alreadySelectedRows,
              onChange: (keys) => {
                setAlreadySelectedRows(keys);
              },
              getCheckboxProps: (record) => ({
                disabled: record.status === 'core_prod_generated',
                // Column configuration not to be checked
              }),
              onSelect: (record) => {
                console.log(record, 'selected row ');
              },
              selections: [
                Table.SELECTION_NONE,
                Table.SELECTION_ALL,

                {
                  key: 'confirmSelected',
                  text: 'Confirm All Selected Rows',
                  onSelect: (allkeys) => {
                    console.log('confirmSelected');
                    confirmAllselectedRecords();
                  },
                },
                {
                  key: 'ConfirmCreateCoreProduct',
                  text: 'Confirm and Create Core Product',
                  onSelect: (allkeys) => {
                    console.log('confirmSelected');
                    generateCoreProductForAllselectedRecords();
                  },
                },
              ],
            }}
          />
        )}
      </div>
      <Modal
        title="Indents List"
        // open={isIndentModalOpen}
        visible={isIndentModalOpen}
        onOk={handleIndentOk}
        onCancel={handleIndentCancel}
      >
        <ul>
          {indentList.map((indentReffName) => (
            <li key={indentReffName}>{indentReffName}</li>
          ))}
        </ul>
      </Modal>
    </div>
  );
};

// const IndentModel = (content) => {
//   <Modal
//     title="Indents List"
//     // open={isIndentModalOpen}
//     visible={isIndentModalOpen}
//     onOk={handleIndentOk}
//     onCancel={handleIndentCancel}
//   >
//     <ul>
//       <li>content1</li>
//       <li>content2</li>
//       <li>content3</li>
//     </ul>
//   </Modal>;
// };

export default ProductOptionList;
